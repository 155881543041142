import { useFormik } from "formik";
import { refinanceCalculatorSchema } from "../../../utils/schemas";
import { Button, NumberInput } from "../../common";
import { useState } from "react";

export default function RefinanceCalculator() {
  const [equity, setEquity] = useState<number>();
  const onCalculate = () => {
    let property = parseInt(formik.values.currentPropertyValue) || 0;
    let ltv = parseInt(formik.values.totalLTV) || 0;
    let balance = parseInt(formik.values.current1stMortgageBalance);

    let maxMortgage = property * (ltv / 100);
    setEquity(maxMortgage - balance);
  };

  const formik = useFormik({
    initialValues: {
      currentPropertyValue: "",
      totalLTV: "",
      current1stMortgageBalance: "",
    },
    validationSchema: refinanceCalculatorSchema,
    onSubmit: onCalculate,
  });

  return (
    <>
      <div className="bg-neutral-100 p-8 rounded-xl">
        <h2 className="text-3xl lg:text-3xl mb-4">Refinance Calculator</h2>
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 items-end gap-x-0 mb-4 lg:gap-x-4">
            <NumberInput
              type="number"
              label="Current Property Value ($)"
              name="currentPropertyValue"
              placeholder="$"
              value={formik.values.currentPropertyValue}
              onChange={(val) =>
                formik.setFieldValue("currentPropertyValue", val)
              }
              errors={formik.errors.currentPropertyValue}
              touched={formik.touched.currentPropertyValue}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
            <NumberInput
              type="number"
              label="Total LTV (Maximum limit)"
              name="totalLTV"
              placeholder="LTV"
              value={formik.values.totalLTV}
              onChange={(val) => formik.setFieldValue("totalLTV", val)}
              errors={formik.errors.totalLTV}
              touched={formik.touched.totalLTV}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
            <NumberInput
              type="number"
              label="Current 1st Mortgage Balance"
              name="current1stMortgageBalance"
              placeholder="$"
              value={formik.values.current1stMortgageBalance}
              onChange={(val) =>
                formik.setFieldValue("current1stMortgageBalance", val)
              }
              errors={formik.errors.current1stMortgageBalance}
              touched={formik.touched.current1stMortgageBalance}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
            <div className="text-end mb-3">
              <Button
                variant="primary"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Calculate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {equity !== undefined && (
        <div className="bg-neutral-100 p-8 rounded-xl mt-5">
          <p className="text-2xl font-semibold">
            Available Equity in the Property:{" "}
            <span className="text-cred-100">
              ${equity.toLocaleString("en-US", { maximumFractionDigits: 2 })}
            </span>
          </p>
        </div>
      )}
    </>
  );
}
