import { useFormik } from "formik";
import { prepaymentChargeCalculatorSchema } from "../../../utils/schemas";
import { Button, NumberInput } from "../../common";
import { useState } from "react";

export default function PrepaymentChargeCalculator() {
  const [monthlyPayment, setMonthlyPayment] = useState<number>();

  const onCalculate = () => {
    let p = parseInt(formik.values.loan) || 0;
    let n = parseInt(formik.values.amortizationPeriod) || 0;
    let r = parseInt(formik.values.rate) || 0;
    r = r / 100 / 12;
    let numerator = p * (r * Math.pow(1 + r, n));
    let denominator = Math.pow(1 + r, n) - 1;
    setMonthlyPayment(numerator / denominator);
  };

  const formik = useFormik({
    initialValues: {
      loan: "",
      rate: "",
      amortizationPeriod: "",
    },
    validationSchema: prepaymentChargeCalculatorSchema,
    onSubmit: onCalculate,
  });

  return (
    <>
      <div className="bg-neutral-100 p-8 rounded-xl">
        <h2 className="text-3xl lg:text-3xl mb-4">
          Mortgage Payment Calculator
        </h2>
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-0 mb-4 lg:gap-x-4">
            <NumberInput
              type="number"
              label="Loan ($)"
              name="loan"
              placeholder="$"
              value={formik.values.loan}
              onChange={(val) => formik.setFieldValue("loan", val)}
              errors={formik.errors.loan}
              touched={formik.touched.loan}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
            <NumberInput
              type="number"
              label="Annual Rate %"
              name="rate"
              placeholder="%"
              value={formik.values.rate}
              onChange={(val) => formik.setFieldValue("rate", val)}
              errors={formik.errors.rate}
              touched={formik.touched.rate}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
            <NumberInput
              type="number"
              label="Amortization Period (months)"
              name="amortizationPeriod"
              placeholder="months"
              value={formik.values.amortizationPeriod}
              onChange={(val) =>
                formik.setFieldValue("amortizationPeriod", val)
              }
              errors={formik.errors.amortizationPeriod}
              touched={formik.touched.amortizationPeriod}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
          </div>
          <div className="flex items-center gap-3">
            <Button
              variant="primary"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Calculate
            </Button>
            <Button
              variant="transparent-secondary"
              onClick={() => {
                formik.resetForm();
                setMonthlyPayment(undefined);
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
      {monthlyPayment !== undefined && (
        <div className="bg-neutral-100 p-8 rounded-xl mt-5">
          <p className="text-2xl font-semibold text-center lg:text-start">
            Monthly Mortgage Amount:{" "}
            <span className="text-cred-100">
              $
              {monthlyPayment.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </p>
        </div>
      )}
    </>
  );
}
