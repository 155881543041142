import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import CountUp from "react-countup";

interface FactItemProps {
  title: string;
  number: number;
  postfix: string;
  description: string;
}

const FactItem: React.FC<FactItemProps> = ({
  title,
  number,
  postfix,
  description,
}) => (
  <div className="overflow-hidden flex flex-col">
    <p className="text-6xl text-cred-100 font-medium border-b border-neutral-900 pb-3 mb-3">
      <CountUp end={number} />
      {postfix}
    </p>
    <p className="text-lg font-semibold mb-3">{title}</p>
    <p>{description}</p>
  </div>
);

const FunFacts = () => {
  const [visibility, setVisibility] = useState(false);
  return (
    <div className="pb-10">
      <div className="container mx-auto px-6">
        <Fade
          direction="up"
          triggerOnce
          onVisibilityChange={(inView: boolean) =>
            inView && setVisibility(true)
          }
        >
          {visibility && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
              <FactItem
                title="Mortgage Agents"
                number={14}
                postfix="+"
                description="More than £19m GBP equity value created across our Platform and Portfolio companies."
              />
              <FactItem
                title="Customers"
                number={2000}
                postfix="+"
                description="We've achieved an average cost saving of 43% in technical spend for our portfolio, partners, and clients"
              />
              <FactItem
                title="Job Creation"
                number={67}
                postfix=""
                description="Enabling young professionals and experienced corporate talent to transition into roles within the vibrant early-stage tech ecosystem."
              />
            </div>
          )}
        </Fade>
      </div>
    </div>
  );
};

export default FunFacts;
