import { MdHomeFilled } from "react-icons/md";
import { DefaultLayout } from "../layouts";
import { TitleSection, MortgageCalculatorsScreen } from "../components";

export default function MortgageCalculators() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Mortgage Calculators", link: "#" },
  ];

  return (
    <DefaultLayout>
      <TitleSection
        title="Mortgage Calculators"
        bg="/images/bad-credit-loan-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <MortgageCalculatorsScreen />
    </DefaultLayout>
  );
}
