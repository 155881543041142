import { useFormik } from "formik";
import { applyContactSchema } from "../../../utils/schemas";
import { Button, CSelect, Input } from "../../common";
import { BsCheckCircle } from "react-icons/bs";

type stepProps = {
  onBackStep: () => void;
  onFormSubmit: (values: any) => void;
};

export default function ApplyStepTwo({ onBackStep, onFormSubmit }: stepProps) {
  const options = [
    { label: "Please Choose an Option", value: "" },
    { label: "Alberta(AB)", value: "AB" },
    { label: "British Columbia(BC)", value: "BC" },
    { label: "Manitoba(MB)", value: "MB" },
    { label: "New Brunswick(NB)", value: "NB" },
    { label: "New Foundland and Labrador(NL)", value: "NL" },
    { label: "Nova Scotia(NS)", value: "NS" },
    { label: "Norhtwest Territories(NT)", value: "NT" },
    { label: "Nunavut(NU)", value: "NU" },
    { label: "Ontario(ON)", value: "ON" },
    { label: "Prince Edward Island(PE)", value: "PE" },
    { label: "Quebec(QC)", value: "QC" },
    { label: "New Brunswick(NB)", value: "NB" },
    { label: "Saskatchewan(SK)", value: "SK" },
    { label: "Yukon(NB)", value: "YT" },
  ];

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      province: "",
      specialist: "",
    },
    validationSchema: applyContactSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <div className="col-span-2 p-4 md:p-6">
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="fullName"
          label="Full Name"
          placeholder="Enter Full Name"
          value={values.fullName}
          onChange={handleChange}
          errors={errors.fullName}
          touched={touched.fullName}
        />
        <Input
          type="email"
          name="email"
          label="Email"
          placeholder="Enter Email"
          value={values.email}
          onChange={handleChange}
          errors={errors.email}
          touched={touched.email}
        />
        <Input
          type="tel"
          name="phone"
          label="Phone"
          placeholder="Enter Phone"
          value={values.phone}
          onChange={handleChange}
          errors={errors.phone}
          touched={touched.phone}
        />
        <CSelect
          name="province"
          label="Province"
          options={options}
          onChange={(e) => setFieldValue("province", e.target.value)}
          errors={errors.province}
          touched={touched.province}
        />
        <Input
          type="text"
          name="specialist"
          label="Mortgage Specialist"
          placeholder="Enter Specialist's Name"
          value={values.specialist}
          onChange={handleChange}
          errors={errors.specialist}
          touched={touched.specialist}
        />
        <p className="text-sm text-gray-500">
          (if you have already spoken to one of our specialists then please
          enter their name.)
        </p>
        <Button
          type="submit"
          variant="secondary"
          className="flex items-center gap-2 mt-3"
          onClick={handleSubmit}
          isDisabled={isSubmitting}
        >
          <span>Submit</span>
          <BsCheckCircle />
        </Button>
      </form>
    </div>
  );
}
