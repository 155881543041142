import React, { useState } from "react";
import { NumericFormat } from "react-number-format";

interface InputProps {
  label?: string;
  labelLinkText?: string;
  labelLink?: string;
  type?: string;
  name: string;
  placeholder?: string;
  className?: string;
  labelClass?: string;
  parentClass?: string;
  onChange?: (e: number | undefined) => void;
  value?: any;
  errors?: any;
  touched?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
}

export default function NumberInput({
  label,
  labelLinkText,
  labelLink,
  type = "text",
  className,
  labelClass,
  parentClass,
  errors,
  touched,
  onChange,
  ...inputProps
}: InputProps) {
  return (
    <div className={`my-3 ${parentClass}`}>
      {label && (
        <div className="flex flex-wrap gap-2 items-center">
          <label className={`block font-bold mb-2 ${labelClass}`}>
            {label}
          </label>
          {labelLink && (
            <a href={labelLink} className="text-blue-800 font-bold mb-2">
              {labelLinkText}
            </a>
          )}
        </div>
      )}
      <NumericFormat
        className={`w-full rounded border border-gray-300 focus:outline-gray-400 p-2 ${className}`}
        onValueChange={(val) => {
          onChange && onChange(val.floatValue);
        }}
        thousandSeparator
        {...inputProps}
      />
      {errors && touched && (
        <p className="text-sm text-red-500 mt-2">{errors}</p>
      )}
    </div>
  );
}
