import { useNavigate } from "react-router-dom";
import { ASSETS_URL } from "../../service";
import Button from "./Button";

type CardProps = {
  data: {
    _id: string;
    heading: string;
    description: string;
    image: string;
  };
};

export default function NewsCard({ data }: CardProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-xl border border-white">
      <img
        src={`${
          data.image == "avatar.jpg" || !data.image
            ? "/images/residential-bg.jpg"
            : ASSETS_URL + data.image
        }`}
        className="w-full h-60 object-cover object-center rounded-t-xl"
      />
      <div className="p-4">
        <p className="text-lg font-bold mb-3 line-clamp-2">{data.heading}</p>
        <p className={`text-gray-600 mb-3 line-clamp-3`}>{data.description}</p>
        <Button variant="primary" onClick={() => navigate(`/news/${data._id}`)}>
          Show More
        </Button>
      </div>
    </div>
  );
}
