import * as yup from "yup";

export const contactValidation = yup.object().shape({
  name: yup.string().required("Name Required"),
  email: yup.string().email("Invalid Email").required("Email Required"),
  subject: yup.string().required("Subject Required"),
  message: yup.string().required("Message Required"),
});

export const applyContactSchema = yup.object().shape({
  fullName: yup.string().required("Full Name required!"),
  email: yup.string().required("Email required!"),
  phone: yup.string().required("Phone required!"),
  province: yup.string().required("Province required!"),
  specialist: yup.string(),
});

export const applyFormSchema = yup.object().shape({
  mortgageType: yup.string().required("Select Mortgage Type!"),
  mortgageAmount: yup
    .number()
    .min(1, "Enter Mortgage Amount!")
    .required("Enter Mortgage Amount!"),
  fundRequired: yup.string().required("Select Funds Required!"),
  livingStatus: yup.string().required("Select Current Living Status!"),
  noOfApplicants: yup.string().required("Select Number of Applicants!"),
});

export const affordabilityCalculatorSchema = yup.object().shape({
  desiredMortgagePayment: yup
    .number()
    .min(1, "Desired Mortgage Payment Required!")
    .required("Desired Mortgage Payment Required!"),
  income: yup.number().min(1, "Income Required!").required("Income Required!"),
  coapplicantIncome: yup.number(),
  downPayment: yup.number(),
  futureHomeLocation: yup.string().required("Location Required!"),
  annualPropertyTax: yup.number(),
  monthlyCondoFees: yup.number(),
  monthlyHeatingCosts: yup.number(),
  creditCardDebtPayment: yup.number(),
  carPayment: yup.number(),
  otherLoanExpenses: yup.number(),
});

export const prepaymentChargeCalculatorSchema = yup.object().shape({
  loan: yup.number().min(1, "Loan Required!").required("Loan Required!"),
  rate: yup.number().min(1, "Rate Required!").required("Rate Required!"),
  amortizationPeriod: yup
    .number()
    .min(1, "Amortization Period Required!")
    .required("Amortization Period Required!"),
});

export const bridgeLoanCalculatorSchema = yup.object().shape({
  annualPropertyAppreciation: yup
    .number()
    .min(1, "Annual Property Appreciation Required!")
    .required("Annual Property Appreciation Required!"),
  downPayment: yup
    .number()
    .min(1, "Down Payment Required!")
    .required("Down Payment Required!"),
  owedOnProperty: yup
    .number()
    .min(1, "Owed on Property Required!")
    .required("Owed on Property Required!"),
  propertyPrice: yup
    .number()
    .min(1, "Property Price Required!")
    .required("Property Price Required!"),
  loanOriginationFeeType: yup.string().required("Selection Required!"),
  loanOriginationFee: yup
    .number()
    .min(1, "Loan Origination Fee Required!")
    .required("Loan Origination Fee Required!"),
  organisationFee: yup.number(),
  annualInterestRate: yup
    .number()
    .min(1, "Annual Interest Rate Required!")
    .required("Annual Interest Rate Required!"),
  amortizationTermType: yup.string().required("Selection Required!"),
  amortizationTerm: yup
    .number()
    .min(1, "Amortization Term Required!")
    .required("Amortization Term Required!"),
  balloonPaymentDueType: yup.string().required("Selection Required!"),
  balloonPaymentDue: yup
    .number()
    .min(1, "Balloon Payment Due Required!")
    .required("Balloon Payment Due Required!"),
});

export const landTransferTaxCalculatorSchema = yup.object().shape({
  price: yup.number().min(1, "Price Required!").required("Price Required!"),
  location: yup.string().required("Location Required!"),
  firstTimeBuyer: yup.boolean().required("Selection Required!"),
});

export const helocLimitCalculatorSchema = yup.object().shape({
  homeValue: yup
    .number()
    .min(1, "Home Value Required!")
    .required("Home Value Required!"),
  remainingMortgage: yup
    .number()
    .min(1, "Remaining Mortgage Required!")
    .required("Remaining Mortgage Required!"),
});

export const helocCalculatorSchema = yup.object().shape({
  currentHelocBalance: yup
    .number()
    .min(1, "Current HELOC Balance Required!")
    .required("Current HELOC Balance Required!"),
  interestOnlyPeriod: yup
    .number()
    .min(1, "Interest Only Period Required!")
    .required("Interest Only Period Required!"),
  firstPaymentDate: yup.string().required("First Payment Date Required!"),
  interestRate: yup
    .number()
    .min(1, "Interest Rate Required!")
    .required("Interest Rate Required!"),
  repaymentPeriod: yup
    .number()
    .min(1, "Repayment Period Required!")
    .required("Repayment Period Required!"),
  amortizationSchedule: yup.string().required("Selection Required!"),
});

export const refinanceCalculatorSchema = yup.object().shape({
  currentPropertyValue: yup
    .number()
    .min(1, "Current Property Value Required!")
    .required("Current Property Value Required!"),
  totalLTV: yup
    .number()
    .min(1, "Total LTV Required!")
    .required("Total LTV Required!"),
  current1stMortgageBalance: yup
    .number()
    .min(1, "Current 1st Mortgage Balance Required!")
    .required("Current 1st Mortgage Balance Required!"),
});

export const debtConsolidationCalculatorSchema = yup.object().shape({
  debts: yup.array().of(
    yup.object().shape({
      debtName: yup.string().required("Debt Name Required!"),
      remainingBalance: yup
        .number()
        .min(1, "Remaining Balance Required!")
        .required("Remaining Balance Required!"),
      monthlyPayment: yup
        .number()
        .min(1, "Monthly Payment Required!")
        .required("Monthly Payment Required!"),
      interestRate: yup
        .number()
        .min(1, "Interest Rate Required!")
        .required("Interest Rate Required!"),
    })
  ),
  consolidationLoanAmount: yup
    .number()
    .min(1, "Amount Required!")
    .required("Amount Required!"),
  interestRate: yup
    .number()
    .min(1, "Interest Rate Required!")
    .required("Interest Rate Required!"),
  loanTerm: yup
    .number()
    .min(1, "Loan Term Required!")
    .required("Loan Term Required!"),
});
