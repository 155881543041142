import { Fade } from "react-awesome-reveal";
import AffordabilityCalculator from "../services/bad-credit-loan-screen/AffordabilityCalculator";
import PrepaymentChargeCalculator from "../services/private-mortgages-screen/PrepaymentChargeCalculator";
import HELOCLimitCalculator from "../services/heloc-screen/HELOCLimitCalculator";
import DebtConsolidationCalculator from "../services/debt-consolidation-screen/DebtConsolidationCalculator";
import RefinanceCalculator from "../services/refinance-screen/RefinanceCalculator";

export default function MortgageCalculatorsScreen() {
  return (
    <section className="px-6 py-16 lg:py-24">
      <div className="container mx-auto">
        <div className="flex flex-col gap-10">
          <Fade direction="up" triggerOnce>
            <AffordabilityCalculator />
          </Fade>
          <Fade direction="up" triggerOnce>
            <PrepaymentChargeCalculator />
          </Fade>
          <Fade direction="up" triggerOnce>
            <HELOCLimitCalculator />
          </Fade>
          <Fade direction="up" triggerOnce>
            <DebtConsolidationCalculator />
          </Fade>
          <Fade direction="up" triggerOnce>
            <RefinanceCalculator />
          </Fade>
        </div>
      </div>
    </section>
  );
}
