import Calculator from "./calculator";
import { Fade } from "react-awesome-reveal";
import { useEffect, useState } from "react";
import RatesModel from "../../../service/models/RatesModel";
import { Input } from "../../common";
import { CalculatorData } from "./data";

export default function CalculatorSection() {
  const theadData = ["Term", "Rates From", "Payment as low as"];
  const [tbodyData, setTbodyData] = useState(CalculatorData);
  const [mortgageSize, setMortgageSize] = useState("500000");
  const [rate, setRate] = useState();

  useEffect(() => {
    const fetchRate = async () => {
      try {
        let response = await RatesModel.getRate();
        setRate(response.data.data[0].rate);
      } catch (error) {
        console.log(error);
      }
    };
    fetchRate();
  }, []);

  useEffect(() => {
    const onSizeChanged = () => {
      let tempData = [...tbodyData];
      tempData.map((item) => {
        let price = parseFloat(mortgageSize);
        let interest = parseFloat(item.ratesFrom) / 1200;
        let month = 360;

        let numinator = price * interest * Math.pow(1 + interest, month);

        let dominator = Math.pow(1 + interest, month) - 1;

        let total = numinator / dominator;

        let lastValue = Math.round(total);

        item.payment = lastValue.toString();
      });
      setTbodyData(tempData);
    };
    onSizeChanged();
  }, [mortgageSize]);

  return (
    <section className="bg-neutral-900 text-white py-12 lg:py-24">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl lg:text-5xl">Calculate Your Mortgage</h2>
        <div className="bg-neutral-800 rounded-lg px-10 py-4 mt-10 mb-4">
          <p className="text-2xl lg:text-3xl font-bold mb-6 w-full lg:w-2/3">
            Prime Rates From{" "}
            <span className="text-cred-100 text-4xl lg:text-5xl">{rate}%</span>
          </p>
          <p className="text-base">
            Check the latest rate using our mortgage rate calculator. All the
            rates are updated on the latest policies and terms.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-28 bg-neutral-800 p-10">
          <Fade direction="left" delay={300} triggerOnce>
            <div>
              <h3 className="text-3xl lg:text-4xl mb-6 w-full lg:w-1/2">
                Enter Your Mortgage Size
              </h3>
              <div className="grow">
                <Input
                  type="range"
                  name="mortgageRange"
                  min={100000}
                  max={1000000}
                  step={5000}
                  value={mortgageSize}
                  onChange={(e) => setMortgageSize(e.target.value)}
                  className="range accent-cred-100 w-full"
                />
              </div>
              <div className="w-2/5 md:w-1/5">
                <Input
                  name="mortgageValue"
                  value={"$" + mortgageSize}
                  readOnly={true}
                  className="bg-cred-100 border-0 text-xl text-center"
                />
              </div>
            </div>
          </Fade>
          <div>
            <Fade direction="up" triggerOnce>
              <Calculator theadData={theadData} tbodyData={tbodyData} />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}
